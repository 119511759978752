@media only screen and (max-width: 1710px) {
    .image .header_mobile {
        width: 620px;
    }

    .reviewImg img {
        width: 800px;
    }

    .world .worldImg img {
        width: 1050px;
    }

    .text .heading h1 {
        font-size: 70px;
    }
}

@media only screen and (max-width: 1630px) {

    .App .blur-image {
        height: 900px;
    }

    .image .header_mobile {
        width: 420px;
    }

    .reviewImg img {
        width: 600px;
    }

    .world .worldImg img {
        width: 950px;
    }

    .world .worldHeading h1 {
        font-size: 60px
    }

    .world .worldSubheading p {
        font-size: 20px
    }

    .world .worldCount h1 {
        font-size: 60px;
    }

    .world .worldCount p {
        font-size: 30px;
    }

    .text .heading h1 {
        font-size: 50px;
    }

    img .footerIcons {
        width: 1400px;
    }

    .cards {
        flex-direction: column;
        align-items: center;
    }

    .templateText .templateHeading h1 {
        font-size: 60px;
    }


    .templateText .templateSubHeading {
        font-size: 20px;
    }

    .items .btns {
        font-size: 35px;
    }

    .items .btn1 {
        font-size: 35px;
    }

    .reviewTxt .reviewSubheading {
        font-size: 20px;
    }

    .reviewTxt .reviewHeading h1 {
        font-size: 60px;
    }

    .reviewTxt .reviewCard h1 {
        font-size: 60px;
    }

    .reviewTxt .reviewCard .reviewStar {
        font-size: 40px;
    }

    .reviewDownBtn img {
        width: 250px;
    }

    .footerContainer .footerText h1 {
        font-size: 60px;
    }

    .footerContainer .footerText p {
        font-size: 20px;
    }


}

@media only screen and (max-width: 1770px) {

    .footerImg .footerIcons {
        width: 1400px;
    }
}

@media only screen and (max-width: 1410px) {

    .footerImg .footerIcons {
        width: 1200px;
    }
}


@media only screen and (max-width: 1200px) {

    .footerImg .footerIcons {
        width: 1000px;
    }
}

@media only screen and (max-width: 1130px) {
    /* .App .blur-image {
        height: 900px;
    } */

    .image .header_mobile {
        width: 320px;
    }

    .reviewImg img {
        width: 500px;
    }

    .world .worldImg img {
        width: 850px;
    }

    .world .worldHeading h1 {
        font-size: 50px
    }

    .world .worldSubheading p {
        font-size: 15px
    }

    .world .worldCount h1 {
        font-size: 50px;
    }

    .world .worldCount p {
        font-size: 20px;
    }

    .text .heading h1 {
        font-size: 50px;
    }

    img .footerIcons {
        width: 1400px;
    }

    /* //////////////////////////////////////////////// */



    .templateText .templateHeading h1 {
        font-size: 50px;
    }


    .templateText .templateSubHeading {
        font-size: 20px;
    }

    .items .btns {
        font-size: 25px;
    }

    .items .btn1 {
        font-size: 25px;
    }

    .reviewTxt .reviewHeading h1 {
        font-size: 50px;
    }

    .reviewTxt .reviewSubheading {
        font-size: 15px;
    }


    .footerContainer .footerText p {
        font-size: 15px;
        width: 50%;
    }
}


@media only screen and (max-width: 1000px) {

    /* .Container {
        flex-direction: column-reverse;
        align-items: center;
    } */


    .App .blur-image {
        height: 650px;
    }

    .image .header_mobile {
        width: 300px;
    }

    .reviewImg img {

        width: 450px;
    }

    .text .downloadBtn img {
        width: 200px;
    }

    .world .worldImg img {
        width: 750px;
    }

    .world .worldHeading h1 {
        font-size: 40px;
    }

    .world .worldSubheading p {
        font-size: 15px
    }

    .world .worldCount h1 {
        font-size: 40px;
    }

    .world .worldCount p {
        font-size: 30px;
    }

    .text .heading h1 {
        font-size: 30px;
    }

    .text .subHeading p {
        font-size: 15px;
    }

    .text .subHeading {
        width: 450px;
    }

    img .footerIcons {
        width: 1400px;
    }

    .cards {
        flex-direction: column;
        align-items: center;
    }

    .templateText .templateHeading h1 {
        font-size: 40px;
    }


    .templateText .templateSubHeading {
        font-size: 15px;
    }

    .items .btns {
        font-size: 20px;
    }

    .items .btn1 {
        font-size: 20px;
    }

    .reviewTxt .reviewSubheading {
        font-size: 15px;
    }

    .reviewTxt .reviewHeading h1 {
        font-size: 40px;
    }

    .reviewTxt .reviewCard h1 {
        font-size: 40px;
    }

    .reviewTxt .reviewCard .reviewStar {
        font-size: 30px;
    }

    .reviewDownBtn img {
        width: 200px;
    }

    .footerContainer .footerText h1 {
        font-size: 50px;
    }

    .footerContainer .footerText p {
        font-size: 20px;
    }

    .footerImg .footerIcons {
        width: 800px;
    }

}

@media only screen and (max-width: 800px) {
  

    .image .header_mobile {
        width: 250px;
        margin-bottom: 25px;
    }

    .text .heading h1 {
        font-size: 25px;
    }

    .text .subHeading {
        width: 350px;
    }

    .reviewImg img {
        width: 400px;
    }

    .reviewTxt .reviewSubheading {
        font-size: 11px;
    }

    .footerImg .footerIcons {
        width: 600px;
    }

    .footbar .logo h1 {
        font-size: 35px;
    }

    .footbar .logos .iconSocial {
        font-size: 40px;
    }

    .footbar .logos .iconSocialFacebook {
        font-size: 35px;
    }

    .footbar .itemCopy .items a {
        margin: 0px 20px 0 20px;
    }

    .footbar .copyRight p {
        font-size: 12px;
    }

}

@media only screen and (max-width: 600px) {
    .reviewTxt{
        margin: 0 !important;
    }
    .slide img {
        margin-top: 40px;
        width: 95% !important;
        margin: auto;
        height: 545px !important;
        border-radius: 30px;
    }
    .Container {
        flex-direction: column-reverse;
        align-items: center;
        margin-top: 50px !important;
    }

    .mainContainer .navItems {
        display: none;
    }

    .reviewImg img {
        width: 280px;
    }

    .world .worldImg img {
        width: 500px;
    }

    .reviewTxt .reviewSubheading {
        font-size: 15px;
    }

    .reviewTxt .reviewHeading h1 {
        font-size: 30px;
    }

    .reviewTxt .reviewHeading .reviewWord {
        color: white;
        border: none;
        -webkit-text-stroke-width: 0px;
    }

    .reviewContainer {
        flex-direction: column;
        align-items: center;
    }

    .reviewTxt .reviewCard {
        background: none;
    }

    .footerImg .footerIcons {
        width: 400px;
    }
}

@media only screen and (max-width: 425px) {
    .mainContainer .logo img {
        width: 100px;
    }

    .mainContainer .btn button {
        font-size: 12px;
        padding: 5px 15px;
    }

    .mainContainer .btn button .crown {
        font-size: 12px;
    }

    .text .heading h1 #photo {
        color: white;
        border: none;
        -webkit-text-stroke-width: 0px;
    }

    .cards .cardCon {
        width: 300px;
    }

    .card .card-1 .card__title {
        font-size: 30px;
    }

    .card .card-1 .card__apply {
        font-size: 20px;
    }


    .templateText .templateHeading h1 {
        font-size: 25px;
    }

    .templateText .templateHeading h1 #text {
        color: white;
        border: none;
        -webkit-text-stroke-width: 0px;
    }

    .templateText .templateSubHeading {
        font-size: 12px;
    }

    .items .btn1 {
        font-size: 12px;
        margin: 0 10px 0 0px !important;
    }

    .items .btns {
        font-size: 12px;
        margin: 0 10px 0 0px !important;
    }

    .reviewTxt .reviewCard {
        /* background: #13244C; */
        justify-content: left;
    }

    .world .worldHeading h1 #worldWord {
        color: white;
        border: none;
        -webkit-text-stroke-width: 0px;
    }

    .world .worldHeading h1 {
        font-size: 30px;
    }

    .world .worldCount p {
        font-size: 20px;
    }

    .world .worldImg img {
        width: 350px;
    }

    .footerContainer .footerText h1 #editingWord {
        color: white;
        border: none;
        -webkit-text-stroke-width: 0px;
    }

    .footerContainer .footerText h1 {
        font-size: 30px;
    }

    .footerContainer .footerText p {
        font-size: 15px;
    }

    .footerText a img {
        width: 200px;
    }

    .footerImg .footerIcons {
        width: 300px;
    }

    .footItems .snapCial h1 {
        font-size: 20px;
    }

    .footItems .footList li {
        font-size: 12px;
    }

    .footItems .footIcons .footIcon {
        font-size: 20px;
    }
}


@media only screen and (max-width: 450px) {
    .mainContainer .logo img {
        width: 150;
    }

    .footerContainer .footerText h1 #editingWord {
        color: white;
        border: none;
        -webkit-text-stroke-width: 0px;
    }

    .world .worldHeading h1 #worldWord {
        color: white;
        border: none;
        -webkit-text-stroke-width: 0px;
    }

    .templateText .templateHeading h1 #text {
        color: white;
        border: none;
        -webkit-text-stroke-width: 0px;
    }

    .text .heading h1 #photo {
        color: white;
        border: none;
        -webkit-text-stroke-width: 0px;
    }

    .cards .cardCon {
        width: 300px;
    }

    .card .card-1 .card__title {
        font-size: 30px;
    }

    .card .card-1 .card__apply {
        font-size: 20px;
    }

    .templateText .templateHeading h1 {
        font-size: 20px;
    }

    .templateText .templateSubHeading {
        font-size: 12px;
    }

    .items .btn1 {
        font-size: 12px;
        margin: 0px 5px 0 0px;
    }

    .items .btns {
        font-size: 12px;
        margin: 0 5px 0 5px;
    }

    .world .worldCount p {
        font-size: 20px;
    }

    .world .worldImg img {
        width: 350px;
    }

    .reviewImg img {
        width: 320px;
    }

    .reviewTxt .reviewCard {
        margin-top: 25px;
        justify-content: left;
    }
}

@media only screen and (max-width: 320px) {
    .templateText .templateHeading h1 {
        font-size: 18px;
    }

    .templateText .templateSubHeading {
        font-size: 10px;
    }

    .items .btn1 {
        font-size: 10px;
        margin: 0 5px 0 5px !important;
    }


    .items .btns {
        font-size: 10px;
        margin: 0 5px 0 5px !important;
    }

    .reviewTxt {
        margin-left: 0 !important;
    }

    .world .worldCount h1 {
        font-size: 30px;
    }

    .world .worldHeading h1 {
        font-size: 20px;
    }

    .world .worldImg img {
        width: 250px;
    }

    .footerImg .footerIcons {
        width: 250px;
    }

    .text .subHeading {
        width: 250px;
    }

    .footItems .snapCial h1 {
        font-size: 15px;
    }

    .footItems .footList li {
        font-size: 10px;
    }

    .footItems .footIcons .footIcon {
        font-size: 15px;
    }
}