.Container {
    margin-top: 150px;
    display: flex;
    width: 80%;
    margin-left: 10%;
}

.image img {
    width: 696px;
}

.downloadBtn img {
    width: 300px;
    margin-left: -20px;
    margin-top: 20px;
}

.heading h1 {
    font-size: 95px;
    color: white;
    font-weight: 800;

}

.subHeading p {
    font-size: 25px;
    color: #808CBF;
}

.subHeading {
    width: 590px;

}

#edting {
    /* color: linear-gradient(90deg, #4775F6 0%, #8F92F8 50.52%, #DF47B1 100%); */
    background: linear-gradient(90deg, #4775F6 0%, #8F92F8 50.52%, #DF47B1 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    /* text-fill-color: transparent; */
}

#photo {
    color: rgba(255, 255, 255, 0);
    /* font-size: 50px; */
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: rgb(255, 255, 255);
}

/* @media only screen and (max-width: 1710px) {
    .image img {
        width: 628px;
    }
} */