.templateContainer {
    width: 80%;
    margin-left: 10%;
    margin-top: 7%;
}

.templateText {
    display: flex;
    justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    /* align-items: center; */
}

.templateHeading h1 {
    font-size: 80px;
    font-weight: 800;
}

.templateSubHeading {
    margin-top: 25px;
    width: 45%;
    font-size: 30px;
    color: #808CBF;
}

.templateHeading {
    width: 50%;
    color: white;
}

#text {
    color: rgba(255, 255, 255, 0);
    /* font-size: 50px; */
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: rgb(255, 255, 255);
}

.templateImgs {
    display: flex;
    justify-content: space-between;
}


.slide img {
      margin-top: 40px;
    width: 80%;
    height: 600px;
    border-radius: 30px;
}

.btns ,.btn1 {
    background: none;
    border: none;
    margin: 0 25px 0 25px;
    font-size: 25px;
    color: #808CBF;
    position: relative;
}


.items{
    margin-top: 30px;
}


.items button:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: -12px;
    left: 0;
    background-color: #fff;
    transform-origin: bottom right;
    transition: transform 0.30s ease-out;
}

.items button:hover:after {

    transform: scaleX(1);
    transform-origin: bottom left;
}

.items button:hover {
    color: white;
}

.underline {
    border-top: 2px solid #2F3B5C;
}

.slide {
    width: 100%;
    display: inline-block;
}

/* .slide img {
    width: 184px;
    height: 347.7px;
} */