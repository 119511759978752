.reviewContainer {
    display: flex;
    width: 80%;
    margin-top: 130px;
    margin-left: 10%;
}

.reviewCard {
    background: #13244C;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 25%;
    border-radius: 50px;
}

.reviewCard h1 {
    font-size: 80px;
    margin-right: 20px;
    color: "white";
    background: linear-gradient(90deg, #4775F6 0%, #8F92F8 50.52%, #DF47B1 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.reviewHeading h1 {
    font-size: 80px;
    font-weight: 800;
    color: white;
    margin-top: 40px;
}

.reviewSubheading {
    font-size: 30px;
    color: #808CBF;
}

.bedge {
    margin-left: -20px;
    margin-top: 30px;
    width: 317px;
}

.reviewTxt {
    margin-left: 60px;
}

.reviewWord {
    color: rgba(255, 255, 255, 0);
    /* font-size: 50px; */
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: rgb(255, 255, 255);
}

.reviewMobile {
    width: 864px;
}

.reviewStar {
    font-size: 60px;
}

@media only screen and (max-width: 1710px) {}

/* @media only screen and (max-width: 1635px) {
    .reviewMobile {
        width: 700px;
    }
} */