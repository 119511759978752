.footerIcons {
    width: 1762px;
    position: absolute;
}

.footerGlass {
    position: relative;
}

.footerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    /* align-content: center;
    align-items: center;
    justify-content: center; */
}

.footerText {
    position: absolute;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footerBtn {
    position: absolute;
}

.footerImg {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.footerText h1 {
    font-size: 80px;
    color: white;
    font-weight: 800;
}

.footerText p {
    font-size: 30px;
    color: #808CBF;
    width: 1084px;
    margin: 30px 0 30px 0;
}

.footerText img {
    width: 317px;
}

#editingWord {
    color: rgba(255, 255, 255, 0);
    /* font-size: 50px; */
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: rgb(255, 255, 255);
}