.card-1,
.card-2,
.card-3 {
    background: none;
    /* background: #13244C; */
    color: white;
    text-align: left;
}

.cards {
    display: flex;
    justify-content: space-evenly;
    margin-top: 50px;
    width: 93%;
    margin-left: 3.5%;

}

.card {
    background: #13244C;
    margin: 20px;
    padding: 20px;
    width: 400px;
    min-height: 200px;
    display: flex;
    text-align: left;
    align-items: center;
}

.card__title {
    background: none;
    font-size: 80px;
    font-weight: bold;

}

.card__apply {
    background: none;
    font-size: 30px;
    font-weight: lighter;
}

.line {
    /* border-: 6px solid green; */
    border-left: 1px solid yellow;
    /* border-image-source: linear-gradient(180deg, #4A65D6 0%, #8089C6 18.75%, #FB7DF0 44.27%, #FF476D 66.67%, #FCA758 100%); */
    height: 100px;
    background: none;
}