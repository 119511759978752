* {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* .line {
    border-left: 3px solid;
}*/

.App {
    position: relative;
    background-color: #0c1d42;

}



.blur-image {
    background-image: url("./assets/image/glassy_background.png");
    /* backdrop-filter: blur(100px); */
    /* opacity: 0.7; */

    background-position: center;
    height: 1080px;
    /* background-repeat: no-repeat; */
    background-size: cover;
    /* position: absolute; */
    padding-top: 10px;
}



.grid {
    grid-template-columns: 10% 1fr 1fr 1fr 10%;
    /* grid-gap: 2px; */
    border-left: 2px solid #2F3B5C;
}

.card {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    align-content: center;
}

.world {
    width: 70%;
    margin-left: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
}

.worldHeading h1 {
    font-size: 80px;
    font-weight: 800;
    text-align: center;
    color: white;
}

.worldSubheading p {
    font-size: 30px;
    color: #808CBF;
    width: 85%;
    text-align: center;
    margin-left: 7.5%;
    margin-top: 10px;
}

.worldCount h1 {
    font-size: 80px;
    background: linear-gradient(90deg, #4775F6 0%, #8F92F8 50.52%, #DF47B1 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.worldCount p {
    font-size: 40px;
    color: white;
    text-align: center;
}

.worldImg img {
    margin-top: 90px;
    width: 1190px;
}

#worldWord {
    color: rgba(255, 255, 255, 0);
    /* font-size: 50px; */
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: rgb(255, 255, 255);
}

.logo h1 {
    color: white;
    font-family: 'Pacifico', cursive;
    font-size: 50px;
    font-weight: lighter;
}

/* .footbar {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 150px;
} */

.footItems {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.footList {
    display: flex;
    list-style: none;
}

.footList li {
    padding: 10px;
    color: white;
}

.footCopy {
    text-align: center;
}

.snapCial h1 {
    font-family: 'Pacifico';
    color: white;
    font-weight: 400;
}

.itemCopy {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
}

.items a {
    margin: 0 40px 0 40px;
}

.items {

    margin-bottom: 10px
}

.copyRight {
    margin-top: 25px;
    opacity: 0.5;
    text-align: center;
    font-size: 12px;
}

.iconsSocial {
    font-size: 50px;
    color: white;
}

.iconsSocialFacebook {
    font-size: 45px;
    color: white;
    margin-left: 20px;
}

.logos {
    display: flex;
    align-items: center;
}

.footIcon {
    color: white;
    font-size: 35px;
    margin: 10px;
}

.footCopy p {
    color: rgba(255, 255, 255, 0.404);
    font-size: 12px;
}