.logo img {
    width: 200px;
}

.mainContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin-left: 10%;
    margin-top: 2%;

}

.crown {
    font-size: 25px;
}

.navItems {
    display: flex;
    justify-content: space-evenly;
    width: 30%;
}

.navItems li {
    list-style: none;
}

.navItems li a {
    text-decoration: none;
    color: #808CBF;
}

.navItems a:hover {
    color: white;
}



.btn button {
    background: linear-gradient(90deg, #EFC454 0%, #FF9737 100%);
    border-radius: 30px;
    padding: 10px 30px;
    font-family: 'Poppins', sans-serif;
    color: white;
    font-size: 18px;
    display: flex;
    align-items: center;
    border: none;
}